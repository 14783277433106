// 医保
const name = '医保'
import Layout from '@/view/layout' // 主框架

export default {
  path: '/merchant/yibao',
  component: Layout,
  children: [
    {
      path: 'yibaoarea',
      name: `医保地区`,
      component: () => import('@/view/mall/yibao/yibao_area')
    },
    {
      path: 'yibaouser',
      name: `医保用户`,
      component: () => import('@/view/mall/yibao/yibao_user')
    },
    {
      path: 'yibaogoods',
      name: `医保商品`,
      component: () => import('@/view/mall/yibao/yibao_goods'),
      children: [
        {
          path: 'editor/:itemId?',
          component: () => import('@/view/mall/yibao/goods_detail')
        }
      ]
    },
    {
      path: 'yibaoupload',
      name: `商品导入`,
      component: () => import('@/view/mall/yibao/goods_upload')
    },
    {
      path: 'yibaobill',
      name: `下载医保账单`,
      component: () => import('@/view/mall/yibao/out_bill')
    },
    {
      path: 'yibaoorders',
      name: `医保订单`,
      component: () => import('@/view/mall/yibao/yibao_order'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/yibao/yibao_detail')
        },
        {
          path: 'yborder',
          component: () => import('@/view/mall/yibao/yibao_yibaodetail')
        }
      ]
    },
  ]
}
