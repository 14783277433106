<template>
  <el-dialog
    class="store-dialog"
    width="1100px"
    title="选择蜂创商品"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    append-to-body
    :before-close="cancelAction"
  >
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input type="text" v-model.trim="params.mk_goods_id" placeholder="请输入市场id"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input type="text" v-model.trim="params.name" placeholder="请输入商品名称"></el-input>
      </el-col>
      <el-col :span="4">
        <el-select v-model="params.shelf_status" placeholder="请选择上下架" clearable>
          <el-option
            v-for="item in statusShelf"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="params.enable_status" placeholder="请选择禁用启用" clearable>
          <el-option
            v-for="item in statusEnable"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="params.goods_type" placeholder="请选择商品类型" clearable>
          <el-option
            v-for="item in statusGoods"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="onSeach">搜索</el-button>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      border
      class="tabledata"
      :data="goodsList"
      :height="400"
    >
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="submitGood(scope.row)"
          >
            选择
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="id"
        label="id"
      />
      <el-table-column
        prop="mk_goods_id"
        label="供应链市场id"
      />
      <el-table-column
        prop="goods_name"
        label="名称"
      />
      <el-table-column
        prop="supplier_stock"
        label="库存"
      />
      <el-table-column
        prop="have_normal"
        label="是否添加普通商品"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.have_normal" style="color: var(--themeColor);">已添加</span>
          <span v-else>未添加</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="have_point"
        label="是否添加积分商品"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.have_point" style="color: var(--themeColor);">已添加</span>
          <span v-else>未添加</span>
        </template>
      </el-table-column>
      <el-table-column label="图片" width="80">
        <template slot-scope="scope">
          <el-image style="width: 60px; height: 60px"
            :src="scope.row.main_picurl"
            lazy
            fit="cover"
            :preview-src-list="[scope.row.main_picurl]" />
        </template>
      </el-table-column>
      <el-table-column
        prop="saleprice"
        label="成本价"
      />
      <el-table-column
        prop="market_price"
        label="销售价"
      />
      <el-table-column
        prop="shelf_status"
        label="上下架"
      >
        <template slot-scope="scope">
          {{ getStatus(scope.row.shelf_status, statusShelf) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="enable_status"
        label="启用禁用"
      >
        <template slot-scope="scope">
          {{ getStatus(scope.row.enable_status, statusEnable) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_type"
        label="商品类型"
      >
        <template slot-scope="scope">
          {{ getStatus(scope.row.goods_type, statusGoods) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="content-center content-top-padded">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500]"
        :total="total_count"
        :page-size="page.pageSize"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </el-dialog>
</template>

<script>
  // import { mapGetters } from 'vuex'
  import { jmhd8Goods } from '@/api/goods'
  import { pageMixin } from '@/mixins'
  export default {
    mixins: [pageMixin],
    props: {
      itemsVisible: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        params: {
          shelf_status: '',
          enable_status: '',
          goods_type: '',
          mk_goods_id: '',
          name: ''
        },
        loading: false,
        goodsList: [],
        total_count: 0,
        statusShelf: [
          {label: '上架', value: '10'},
          {label: '下架', value: '20'}
        ],
        statusEnable: [
          {label: '启用', value: '10'},
          {label: '禁用', value: '20'}
        ],
        statusGoods: [
          {label: '实物奖品 ', value: '10'},
          {label: '话费', value: '20'},
          {label: '卡密', value: '30'},
          {label: '充值类型', value: '40'}
        ]
      }
    },
    computed: {
      showDialog () {
        return this.itemsVisible
      },
      // ...mapGetters(['wheight'])
    },
    mounted () {
      this.fetchList()
    },
    methods: {
      fetchList () {
        this.loading = true
        const { pageIndex: page, pageSize } = this.page
        let params = {
          page,
          pageSize,
          ...this.params
        }
        jmhd8Goods(params).then((response) => {
          let list = response.data.data.list
          this.goodsList = list
          this.total_count = response.data.data.total_count
          this.loading = false
        })
      },
      onSeach(){
        this.page.pageIndex = 1
        this.$nextTick(() => {
          this.fetchList()
        })
      },
      handleCurrentChange (pageIndex) {
        this.page.pageIndex = pageIndex
        this.fetchList()
      },
      handleSizeChange (pageSize) {
        this.page.pageIndex = 1
        this.page.pageSize = pageSize
        this.fetchList()
      },
      getStatus(value, list) {
        const item = list.filter(item => item.value === value)
        return item[0].label
      },
      cancelAction () {
        this.$emit('closeGoodsDialog')
      },
      submitGood(row) {
        this.$emit('chooseGoodsDialog', row)
      }
    }
  }
</script>

<style type="text/css" scoped>

</style>
