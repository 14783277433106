import fetch from '../utils/fetch'


// 图片树
export function getimagetree (query) {
  return fetch({
    url: '/espier/image/getimagetree',
    method: 'get',
    params: query
  })
}


export function espierImg (params) {
  return fetch({
    url: '/espier/images',
    method: 'get',
    params
  })
}


export function delImgcat (id) {
  return fetch({
    url: '/espier/image/cat/' + id ,
    method: 'delete',
  })
}


export function addImgCat (params) {
  return fetch({
    url: '/espier/image/cat' ,
    method: 'post',
    params
  })
}

export function movePicToCat (params) {
  return fetch({
    url: '/espier/image/movecat' ,
    method: 'post',
    params
  })
}