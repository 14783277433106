// 交易路由
const name = '交易'
import Layout from '@/view/layout' // 主框架

export default {
  path: '/merchant/order',
  component: Layout,
  children: [
    {
      path: 'tradenormalorders',
      name: `实物订单`,
      component: () => import('@/view/mall/trade/normalorders/list'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/trade/order/detail')
        },
        {
          path: 'process/:itemId?',
          component: () => import('@/view/mall/trade/order/process')
        }
      ]
    },
    {
      path: 'aftersaleslist',
      name: `售后列表`,
      component: () => import('@/view/mall/aftersales/list'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/aftersales/detail')
        }
      ]
    },
    {
      path: 'tradepayment',
      name: `交易单`,
      component: () => import('@/view/mall/trade/list')
    },
    {
      path: 'aftersalesrefundlist',
      name: `退款单`,
      component: () => import('@/view/mall/aftersales/refundList'),
      children: [
        {
          path: 'detail/:itemId?',
          component: () => import('@/view/mall/aftersales/refundDetail')
        }
      ]
    },
    {
      path: 'Refunderrorlogs',
      name: `退款失败日志`,
      component: () => import('@/view/mall/trade/refunderrorlogs')
    },
    {
      path: 'shippingtemplates',
      name: `运费模板`,
      component: () => import('@/view/mall/trade/shipping/templates'),
      children: [
        {
          path: 'editor/:itemId?',
          component: () => import('@/view/mall/trade/shipping/add')
        }
      ]
    },
    {
      path: 'logistics',
      name: `物流公司`,
      component: () => import('@/view/mall/trade/logistics/normal')
    },
    {
      path: 'epidemicRegister',
      name: `疫情订单登记列表`,
      component: () => import('@/view/mall/trade/epidemicRegister')
    },
    {
      path: 'prescription',
      name: `处方列表`,
      component: () => import('@/view/mall/trade/prescription')
    },
    {
      path: 'complex',
      name: `图文问诊处方单`,
      component: () => import('@/view/mall/trade/complexlist')
    },
    {
      path: 'facesheet',
      name: '电子面单账户列表',
      component: () => import('@/view/mall/trade/facesheet')
    },
    {
      path: 'setprint',
      name: '打印设置',
      component: () => import('@/view/mall/trade/setprint')
    },
    {
      path: 'hospital/patientlist',
      name: '就诊人列表',
      component: () => import('@/view/mall/trade/hospital/patientlist')
    },
    {
      path: 'hospital/consultationdemandlist',
      name: '就诊需求列表',
      component: () => import('@/view/mall/trade/hospital/consultationdemandlist')
    }
  ]
}
