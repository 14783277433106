// 会员路由
const name = '统计'
import Layout from '@/view/layout' // 主框架

export default {
  path: '/companydata',
  component: Layout,
  children: [
    {
      path: 'goodsstatistics',
      name: `goodsstatistics`,
      meta: {
        title: `${name}-商品统计`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-goodsstatistics" */ '@/view/mall/datacube/goodsdata'
        )
    },
    {
      path: 'orderstatistics',
      name: `orderstatistics`,
      meta: {
        title: `${name}-交易统计`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-orderstatistics" */ '@/view/mall/datacube/companydata/order'
        )
    },
    {
      path: 'distributordata',
      name: `distributordata`,
      meta: {
        title: `${name}-店铺统计`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-distributordata" */ '@/view/mall/datacube/distributordata'
        )
    },
    {
      path: 'memberstatistics',
      name: `memberstatistics`,
      meta: {
        title: `${name}-会员统计`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-memberstatistics" */ '@/view/mall/datacube/companydata/member'
        )
    },
    {
      path: 'goodsrealtimelist',
      name: `goodsrealtimelist`,
      meta: {
        title: `${name}-商品实时榜单`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-goodsrealtimelist" */ '@/view/mall/datacube/goodsrealtimelist'
        )
    },
    {
      path: 'trendanalysis',
      name: `trendanalysis`,
      meta: {
        title: `${name}-趋势分析`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-trendanalysis" */ '@/view/mall/datacube/trendanalysis'
        )
    },
    {
      path: 'keywordsAnalysisStatistics',
      name: `keywordsAnalysisStatistics`,
      meta: {
        title: `${name}-实时关键词`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-keywordsAnalysisStatistics" */ '@/view/mall/datacube/keywordsAnalysisStatistics'
        )
    },
    {
      path: 'distributorAnalysisStatistics',
      name: `distributorAnalysisStatistics`,
      meta: {
        title: `${name}-门店排行`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-distributorAnalysisStatistics" */ '@/view/mall/datacube/distributorAnalysisStatistics'
        )
    },
    {
      path: 'orderGoodsStatistics',
      name: `orderGoodsStatistics`,
      meta: {
        title: `${name}-客单件数特征`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-orderGoodsStatistics" */ '@/view/mall/datacube/orderGoodsStatistics'
        )
    },
    {
      path: 'memberNewoldStatistics',
      name: `memberNewoldStatistics`,
      meta: {
        title: `${name}-新老客户特征`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-orderGoodsStatistics" */ '@/view/mall/datacube/memberNewoldStatistics'
        )
    },
    {
      path: 'goodsPriceStatistics',
      name: `goodsPriceStatistics`,
      meta: {
        title: `${name}-商品价格带特征`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-orderGoodsStatistics" */ '@/view/mall/datacube/goodsPriceStatistics'
        )
    },
    {
      path: 'goodsMonitorStatistics',
      name: `goodsMonitorStatistics`,
      meta: {
        title: `${name}-单品监控`
      },
      component: () =>
        import(
          /* webpackChunkName: "companydata-orderGoodsStatistics" */ '@/view/mall/datacube/goodsMonitorStatistics'
        ),
      children: [
        {
          path: 'monitordata/:goodsId?',
          component: () => import('@/view/mall/datacube/goodsMonitorStatistics_data')
        },
      ]
    },
  ]
}
