<template>
  <el-dialog
    class="store-dialog"
    title="选择用户"
    append-to-body
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :before-close="cancelAction"
  >
    <div style="margin-bottom: 15px">
      <el-input v-model="params.mobile" placeholder="请输入手机号" />
      <el-input v-model="params.username" placeholder="请输入姓名" />
      <el-button
        slot="append"
        icon="el-icon-search"
        @click="handleIconClick"
      />
    </div>
    <el-table
      v-loading="loading"
      border
      :data="memberData"
    >
      <el-table-column prop="user_id" label="用户ID" width="100" />
      <el-table-column prop="mobile" label="手机号" />
      <el-table-column prop="username" label="昵称" width="140" />
      <el-table-column prop="sex" label="性别" width="70">
        <template slot-scope="scope">
          <span v-if="scope.row.sex == '2'">女</span>
          <span v-else-if="scope.row.sex == '1'">男</span>
          <span v-else-if="scope.row.sex == '0'">未知</span>
          <span v-else>{{ scope.row.sex }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="handleMemberChange(scope.row)">
            选择
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="content-center content-top-padded">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="page.pageIndex"
        :page-sizes="[20, 30, 50, 100, 300, 500]"
        :total="page.total"
        :page-size="page.pageSize"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      userVisible: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      showDialog () {
        return this.userVisible
      }
    },
    data() {
      return {
        page: {
          pageIndex: 1,
          pageSize: 20,
          total: 0
        },
        params: {
          mobile: '',
          username: ''
        },
        memberData: [],
        loading: false,
        user_id: '',
      }
    },
    mounted() {
      this.getMember()
    },
    methods: {
      handleIconClick() {
        this.page.pageIndex = 1
        this.$nextTick(() => {
          this.getMember()
        })
      },
      handleMemberChange(row) {
        this.user_id = row.user_id
        this.$emit('useridSelect', this.user_id)
      },
      handleCurrentChange (pageIndex) {
        this.page.pageIndex = pageIndex
        this.getMember()
      },
      // 调整每页显示条数
      handleSizeChange (pageSize) {
        this.page.pageSize = pageSize
        this.getMember()
      },
      cancelAction () {
        this.$emit('closeUserDialog')
      },
      async getMember() {
        this.loading = true
        const { pageIndex: page, pageSize } = this.page
        let params = {
          page,
          pageSize,
          ...this.params
        }
        const { list, total_count } = await this.$api.member.getMembers(params)
        this.memberData = list
        this.page.total = total_count
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-table {
    height: 500px;
    overflow-y: auto;
  }
  .el-input {
    width: 200px;
    margin-right: 20px;
  }
</style>
