import fetch from '../utils/fetch'
export function crmList (query) {
  return fetch({
    url: '/crm/synclog/getlist',
    method: 'get',
    params: query
  })
}


export function repair (id) {
  return fetch({
    url: 'crm/healthy/repair/' + id,
    method: 'get',
  })
}


export function retry (id) {
  return fetch({
    url: 'crm/healthy/retry/' + id,
    method: 'get',
  })
}

export function jstList (query) {
  return fetch({
    url: '/jushuitan/getLogList',
    method: 'get',
    params: query
  })
}

export function jstretry (id) {
  return fetch({
    url: '/jushuitan/retry/' + id,
    method: 'get',
  })
}


export function jushuitan_repair (id) {
  return fetch({
    url: 'crm/healthy/jushuitan_repair/' + id,
    method: 'get',
  })
}



export function posList (query) {
  return fetch({
    url: '/xypos/getLogList',
    method: 'get',
    params: query
  })
}

export function posretry (id) {
  return fetch({
    url: '/xypos/retry/' + id,
    method: 'get',
  })
}
// 互联网医院列表
export function hospitalList (query) {
  return fetch({
    url: '/gzch120/sync/log',
    method: 'get',
    params: query
  })
}

// 互联网医院重试
export function hospitalretry (id) {
  return fetch({
    url: '/gzch120/sync/retry/' + id,
    method: 'get',
  })
}
// 互联网医院补单
export function hospitalrepair (id) {
  return fetch({
    url: '/gzch120/sync/repair/' + id,
    method: 'get',
  })
}
// 手动推送订单到pos
export function pushtoposrepair (id) {
  return fetch({
    url: '/xypos/pushtopos/order?order_id=' + id,
    method: 'get',
  })
}